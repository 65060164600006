define('leibniz-frontend/controllers/search', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      changeOrder: function changeOrder(order) {
        this.set('order', order);
      }
    }
  });
});