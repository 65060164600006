define('leibniz-frontend/routes/letter', ['exports', 'leibniz-frontend/mixins/solr'], function (exports, _solr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Route.extend(_solr.default, {
    actions: {
      clearVariantConnectors: function clearVariantConnectors() {
        this.clearVariantConnectors();
      },
      changeLetter: function changeLetter(id) {
        this.transitionTo('letter', id);
      },
      positionVariants: function positionVariants() {
        this.positionVariants();
      }
    },
    model: function model(params) {
      var _this = this;

      var query = 'id:' + params.letter_id + ' OR (doc_id:' + params.letter_id + ' AND type:variante)';
      return this.query(query, { sort: 'type asc' }).then(function (json) {
        var letter = {};
        if (_typeof(json.response) === 'object' && json.response.docs.length > 0) {
          letter = _this.parseResponse(json);
        } else {
          letter.id = params.letter_id;
        }
        // NOTE: query-params can only use this string if it is correctly URL-encoded
        letter.viewQuery = params.view;
        try {
          letter.view = Ember.$.parseJSON(params.view);
        } catch (e) {
          letter.view = null;
        }
        return letter;
      }, function (error) {
        return { error: error };
      });
    },
    renderTemplate: function renderTemplate() {
      var _this2 = this;

      var currentLetter = this.get('controller.model');
      var appController = this.controllerFor('application');

      // all letters in solr
      var allLetters = this.controllerFor('application').get('model');
      // all letters of one correspondence
      var allLettersOfCorrespondence = [];
      // correspondences and its first letter
      var correspondences = [];

      if (allLetters) {
        allLetters.forEach(function (letter, index) {

          if (letter.korrespondenz === currentLetter.korrespondenz) {
            allLettersOfCorrespondence.pushObject(letter);
          }
          if (!correspondences.find(function (element) {
            return element.korrespondenz === letter.korrespondenz;
          })) {
            var el = {};
            el.korrespondenz = letter.korrespondenz;
            el.id = letter.id;
            correspondences.pushObject(el);
          }
        });
      }

      // sort correspondences alphabetically
      var sortedCorrespondences = correspondences.sort(function (a, b) {
        return a.korrespondenz.localeCompare(b.korrespondenz);
      });

      if (currentLetter.korrespondenz) {
        currentLetter.firstLetterID = sortedCorrespondences.find(function (element) {
          return currentLetter.korrespondenz === element.korrespondenz;
        }).id;
      }
      appController.set('current-letter', currentLetter);
      appController.set('correspondences', sortedCorrespondences);

      if (allLettersOfCorrespondence) {
        allLettersOfCorrespondence.forEach(function (letter, index) {
          var id = letter.id;
          if (letter.id === currentLetter.id) {
            _this2.set('controller.model.prevLetter', index > 0 ? allLettersOfCorrespondence[index - 1] : null);
            _this2.set('controller.model.nextLetter', index < allLettersOfCorrespondence.length - 1 ? allLettersOfCorrespondence[index + 1] : null);
            return false;
          }
        });
      }
      this.render();
      this.set('controller.model.allLetters', allLettersOfCorrespondence);
      this.controller.set('rendered', false);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var appController = this.controllerFor('application');
      appController.set('query', null);
      appController.set('showSearch', true);
      controller.set('rendered', true);
    },
    afterModel: function afterModel() {
      var _this3 = this;

      Ember.run.next(function () {
        _this3.activateLinks();

        var promises = [];

        promises.push(_this3.renderMathJax());

        // Convert image references to SVG images
        Ember.$('.transcript, .variants').find('.reference.-image').each(function (index, ref) {
          var imageID = Ember.$(ref).data('id');
          promises.push(new Ember.RSVP.Promise(function (resolve) {
            _this3.loadSVG(imageID).then(function (svg) {
              Ember.$(ref).html(svg);
              resolve(true);
            });
          }));
        });

        // Position variants after MathJax has been rendered and images have been loaded
        Ember.RSVP.Promise.all(promises).then(function () {
          // TODO: For some reason, resize is always triggered once
          // this.positionVariants();

          // Store variant with MathJax and images to eliminate the need to re-run both
          // TODO: Would be nice if MathJax was able to render strings directly
          var variants = _this3.get('controller.model.variants');
          if (typeof variants !== 'undefined') {
            variants.forEach(function (variant) {
              Ember.set(variant, 'text_schnipsel', Ember.$('#' + variant.id + ' .variant_content').html());
            });
          }

          Ember.$('.lane').resize(function () {
            Ember.run.debounce(_this3, _this3.clearVariantConnectors, 333, true);
            Ember.run.debounce(_this3, _this3.positionVariants, 333);
          });

          // remove links from popups
          Ember.$('.pop-up a').contents().unwrap();
          _this3.controller.set('rendered', true);
        });
      });
    },

    // Turn static links into ember transition links
    activateLinks: function activateLinks() {
      var route = this;
      // TODO: Get <a> elements directly
      Ember.$('.metadata').find('a').not('[href^="http://"]').click(function (e) {
        e.preventDefault();
        var letterID = Ember.$(this).attr('href');
        route.transitionTo('letter', letterID);
      });
    },
    loadSVG: function loadSVG(id) {
      var _this4 = this;

      return new Ember.RSVP.Promise(function (resolve) {
        _this4.query('id:' + id).then(function (json) {
          if (json.response.docs.length > 0 && json.response.docs[0].hasOwnProperty('svg_code')) {
            resolve(json.response.docs[0].svg_code);
          }
        });
      });
    },
    renderMathJax: function renderMathJax() {
      var context = this.context;
      return new Ember.RSVP.Promise(function (resolve) {
        if (context.volltext && typeof MathJax !== 'undefined') {
          MathJax.Hub.Queue(['Typeset', MathJax.Hub], function () {
            resolve(true);
          });
        } else {
          resolve(true);
        }
      });
    },
    clearVariantConnectors: function clearVariantConnectors() {
      Ember.$('.content_svg-overlay').remove(); // TODO: Find a more elegant way to re-render SVG
    },
    positionVariants: function positionVariants() {
      var $laneTranscript = Ember.$('.transcript');
      var $laneVariants = Ember.$('.variants');
      var $references = $laneTranscript.find('.reference');
      this.clearVariantConnectors();
      if ($references.length === 0) {
        return;
      }

      var $laneHeader = $laneVariants.find('.lane_header');
      var $variants = $laneVariants.find('.variant');
      var $variantsArr = [];
      var marginBetweenVariants = parseInt($laneVariants.css('line-height')) / 2;
      var prevVariantBottom = $laneHeader.position().top + $laneHeader.outerHeight();
      // NOTE: Shorthand css properties like `padding` are not supported in Firefox

      // Using plain JS for SVG since jQuery struggles with namespaces
      var svgNS = 'http://www.w3.org/2000/svg';
      var svg = document.createElementNS(svgNS, 'svg');
      svg.setAttribute('class', 'content_svg-overlay');
      svg.setAttribute('width', Ember.$('.content').width());
      svg.setAttribute('height', Ember.$('.content').height());
      document.getElementById('content').appendChild(svg);

      $references.each(function () {
        var $this = Ember.$(this);
        var variantID = $this.data('id');
        if (Ember.$.inArray(variantID, $variantsArr) !== -1) {
          return; // variant already defined
        }
        $variantsArr.push(variantID);

        var $variant = $variants.filter('#' + variantID);
        if ($variant.length === 0) {
          return; // variant not available
        }
        var top = $this.position().top;
        var variantTop = top < prevVariantBottom ? prevVariantBottom : top;
        $variant.css({ top: variantTop }).addClass('-visible');

        // Add click handler to highlight reference/variant pair
        // as references can be hidden below others, it has to travel through dom elements
        var $group = $this.add($references.filter('[data-id=' + variantID + ']')).add($variant);
        $group.off('click').click(function () {
          $group.toggleClass('-highlight');
          $group.find("*").toggleClass('-highlight');
          return false;
        });

        // NOTE: Cannot use toggleClass because element can be moved under the
        // mouse pointer which does not trigger mouseEnter
        $group.hover(function () {
          $group.addClass('-hover');
        }, function () {
          $group.removeClass('-hover');
        });

        prevVariantBottom = variantTop + $variant.outerHeight() + marginBetweenVariants;

        // Child variants / variants in variants
        var $childReferences = $variant.find('.reference.-afootnote, .reference.-cfootnote');
        $childReferences.each(function () {
          var childVariantID = Ember.$(this).data('id');
          var $childVariant = $variants.filter('#' + childVariantID);
          $childVariant.css({ top: prevVariantBottom }).addClass('-child -visible');
          prevVariantBottom += $childVariant.outerHeight() + marginBetweenVariants;

          // Add click handler to highlight reference/variant pair
          var $childGroup = Ember.$(this).add($childReferences.filter('[data-id=' + childVariantID + ']')).add($childVariant);

          $childGroup.off('click').click(function () {
            $childGroup.toggleClass('-highlight');
            return false;
          });

          // NOTE: Cannot use toggleClass, see above
          $childGroup.hover(function () {
            $childGroup.addClass('-hover');
          }, function () {
            $childGroup.removeClass('-hover');
          });
        });
      });

      $laneVariants.height(prevVariantBottom);

      // Draw curved SVG-line from (last) reference to respective variant
      Ember.$('.variant').each(function () {
        var id = this.id;
        var reference = Ember.$('.transcript').find('.reference[data-id="' + id + '"]').last();
        var varReference = Ember.$('.variant').find('.reference[data-id="' + id + '"]').last();
        if (reference.length === 0 && varReference.length === 0) {
          return;
        }

        // Draw a curved line from reference to variant
        var path = document.createElementNS(svgNS, 'path');
        // NOTE: This provides an SVG-compatible rgb(...) color value
        var strokeColor = Ember.$(this).find('.variant_border0').css('border-left-color');
        path.setAttribute('stroke', strokeColor);
        path.setAttribute('fill', 'none');
        path.setAttribute('style', 'stroke-width: 1px');
        path.setAttribute('id', id);
        if (reference.length !== 0) {
          var bottomRightElement = reference.children().last();
          var left = bottomRightElement.position().left;
          var bottom = bottomRightElement.position().top + bottomRightElement.outerHeight();
          // 14: padding right of .lane_content
          var pathD = 'M ' + left + ', ' + (bottom - .5) + '\n                     L ' + ($laneTranscript.width() - 14) + ', ' + (bottom - .5) + '\n                     C ' + $laneTranscript.width() + ', ' + (bottom - .5) + ',\n                       ' + $laneTranscript.width() + ', ' + (this.offsetTop + Ember.$(this).outerHeight() / 2) + ',\n                       ' + Ember.$(this).offset().left + ', ' + (this.offsetTop + Ember.$(this).outerHeight() / 2);
          path.setAttribute('d', pathD);
        } else {
          var startX = $laneVariants.position().left + varReference.closest('div').position().left + varReference.position().left;
          var startY = varReference.closest('div').position().top + varReference.position().top + varReference.innerHeight();
          var _left = $laneVariants.position().left + 10;
          var endX = Ember.$(this).offset().left;
          var endY = this.offsetTop + Ember.$(this).outerHeight() / 2;
          var _pathD = 'M ' + (startX + 5) + ', ' + (startY - .5) + '\n                     L ' + (_left + 10) + ', ' + (startY - .5) + '\n                     Q ' + _left + ', ' + (startY - .5) + ', ' + _left + ', ' + (startY + 10) + '\n                     L ' + _left + ', ' + (endY - 10) + '\n                     Q ' + _left + ', ' + endY + ', ' + (_left + 10) + ', ' + endY + '\n                     L  ' + endX + ', ' + endY;
          path.setAttribute('d', _pathD);
        }

        svg.appendChild(path);
      });
    }
  });


  /* global MathJax */
  if (typeof MathJax !== 'undefined') {
    MathJax.Hub.Config({
      extensions: ['tex2jax.js'],
      'HTML-CSS': {
        availableFonts: ['TeX']
      },
      jax: ['input/TeX', 'output/HTML-CSS'],
      styles: { '#MathJax_Message': { display: 'none' } },
      tex2jax: {
        inlineMath: [['$', '$'], ['\\(', '\\)']],
        displayMath: [['$$', '$$'], ['\\[', '\\]']],
        processEscapes: true
      }
    });
  }
});