define('leibniz-frontend/controllers/application', ['exports', 'leibniz-frontend/config/environment', 'leibniz-frontend/mixins/scrolling'], function (exports, _environment, _scrolling) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_scrolling.default, {
    baseURL: _environment.default.baseURL,
    firstLetterID: _environment.default.firstLetterID,
    showNav: false,
    showSearch: true,
    actions: {
      scrollToTop: function scrollToTop() {
        Ember.$('html, body').animate({ scrollTop: 0 });
      },
      search: function search() {
        this.transitionToRoute('search', { queryParams: { query: this.query } });
      },

      toggleNav: function toggleNav() {
        this.set('showNav', !this.get('showNav'));
      },
      changeTo: function changeTo(params) {
        // remove cache to prevent select-bug in firefox
        Ember.$('span.top-bar_correspondence select option').remove();
        this.set('correspondence', params);
        this.transitionToRoute('letter', params);
      }
    },
    scrolled: function scrolled(scrollPos) {
      var scrolledBelowHeader = scrollPos > 99;
      Ember.$('.main_scroll-top').toggleClass('-visible', scrolledBelowHeader);
    }
  });
});