define('leibniz-frontend/adapters/application', ['exports', 'active-model-adapter', 'ember-cli-file-saver/mixins/adapter-arraybuffer-mixin'], function (exports, _activeModelAdapter, _adapterArraybufferMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ApplicationAdapter = _activeModelAdapter.default.extend(_adapterArraybufferMixin.default);

  ApplicationAdapter.reopen({
    //
  });

  exports.default = ApplicationAdapter;
});