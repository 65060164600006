define('leibniz-frontend/components/letter-footer', ['exports', 'leibniz-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    showCitation: false,
    showMetadata: false,
    tagName: '',
    texName: _environment.default.texName
  });
});