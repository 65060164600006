define('leibniz-frontend/components/pop-up', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'pop-up',
    tagName: 'span',
    didInsertElement: function didInsertElement() {
      var $this = this.$();
      var $parent = $this.parent();
      $parent.css('position', 'relative');
      if ($parent.offset().left > 0.5 * Ember.$(window).width()) {
        $this.addClass('-right');
      }
      var newMinWidth = $this.height();
      if (newMinWidth > $this.width()) {
        $this.css({ minWidth: newMinWidth });
      }
    }
  });
});