define('leibniz-frontend/mixins/scrolling', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var onScroll = function onScroll() {
        var scrollPos = Ember.$(window).scrollTop();
        return _this.scrolled(scrollPos);
      };
      this.unbindScrolling();
      Ember.$(window).bind('scroll', onScroll);
      Ember.$(document).bind('touchmove', onScroll);
    },
    unbindScrolling: function unbindScrolling() {
      Ember.$(window).unbind('scroll');
      Ember.$(document).unbind('touchmove');
    },
    scrolled: function scrolled(scrollPos) {
      // Modules using this component must implement a method called 'scrolled'
      return scrollPos;
    }
  });
});