define('leibniz-frontend/routes/search', ['exports', 'leibniz-frontend/mixins/solr'], function (exports, _solr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Route.extend(_solr.default, {
    queryParams: {
      order: {
        replace: true
      },
      query: {
        replace: true
      }
    },
    order: 'reihe asc, band asc, brief_nummer asc',
    actions: {
      queryParamsDidChange: function queryParamsDidChange() {
        Ember.run.debounce(this, this.refresh, 500);
      }
    },
    model: function model(params) {
      var _this = this;

      // TODO: Include variants and add associated letters to results
      this.controllerFor('search').set('rendered', false);
      if (!params.query) {
        return;
      }
      var q = '*' + params.query + '*';
      var query = 'type:brief AND (' + (' volltext:' + q) + (' OR id:' + q) + (' OR reihe:' + q) + (' OR band:' + q) + (' OR brief_nummer:' + q) + (' OR all_suggest:' + q) + (' OR ort_anzeige:' + q) + (' OR datum_anzeige:' + q) + (' OR datum_gregorianisch:' + q) + (' OR datum_julianisch:' + q) + (' OR kontext:' + q) + ')';
      return this.query(query, { sort: params.order }).then(function (json) {
        var model = {};
        if (_typeof(json.response) === 'object' && json.response.docs.length > 0) {
          model.results = json.response.docs;
          console.log("results");
          console.log(model.results);
          model.results.forEach(function (letter) {
            console.log("json");
            console.log(json);
            console.log("done");
            if (json.highlighting[letter.id].volltext) {
              letter.excerpt = _this.strip(json.highlighting[letter.id].volltext);
            } else if (letter.volltext) {
              letter.excerpt = _this.strip(letter.volltext.substr(0, 50));
            } else {
              letter.excerpt = null;
            }
            letter.sentByFocusPerson = letter.absender_nachname[0] === 'Leibniz';
          });
        } else {
          model.results = [];
        }
        _this.controllerFor('search').set('rendered', true);
        return model;
      }, function (error) {
        return { error: error };
      });
    },
    strip: function strip(html) {
      var tmp = document.createElement('div');
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || '';
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('rendered', true);
      this.controllerFor('application').set('showSearch', false);
    }
  });
});