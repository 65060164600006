define("leibniz-frontend/templates/search", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.6.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 7,
                  "column": 10
                },
                "end": {
                  "line": 16,
                  "column": 10
                }
              },
              "moduleName": "leibniz-frontend/templates/search.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("i");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(",\n            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("select");
              dom.setAttribute(el1, "class", "search_order");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("option");
              dom.setAttribute(el2, "value", "reihe asc, band asc, brief_nummer asc");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("option");
              dom.setAttribute(el2, "value", "reihe desc, band desc, brief_nummer desc");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("option");
              dom.setAttribute(el2, "value", "datum_gregorianisch asc");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("option");
              dom.setAttribute(el2, "value", "datum_gregorianisch desc");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [9]);
              var element5 = dom.childAt(element4, [1]);
              var element6 = dom.childAt(element4, [3]);
              var element7 = dom.childAt(element4, [5]);
              var element8 = dom.childAt(element4, [7]);
              var morphs = new Array(13);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5]), 0, 0);
              morphs[3] = dom.createMorphAt(fragment, 7, 7, contextualElement);
              morphs[4] = dom.createAttrMorph(element4, 'onchange');
              morphs[5] = dom.createAttrMorph(element5, 'selected');
              morphs[6] = dom.createMorphAt(element5, 0, 0);
              morphs[7] = dom.createAttrMorph(element6, 'selected');
              morphs[8] = dom.createMorphAt(element6, 0, 0);
              morphs[9] = dom.createAttrMorph(element7, 'selected');
              morphs[10] = dom.createMorphAt(element7, 0, 0);
              morphs[11] = dom.createAttrMorph(element8, 'selected');
              morphs[12] = dom.createMorphAt(element8, 0, 0);
              return morphs;
            },
            statements: [["content", "content.results.length", ["loc", [null, [8, 12], [8, 38]]]], ["inline", "t", ["resultsFor"], [], ["loc", [null, [8, 39], [8, 57]]]], ["content", "query", ["loc", [null, [8, 61], [8, 70]]]], ["inline", "t", ["orderBy"], [], ["loc", [null, [9, 12], [9, 27]]]], ["attribute", "onchange", ["subexpr", "action", ["changeOrder"], ["value", "target.value"], ["loc", [null, [10, 50], [10, 95]]]]], ["attribute", "selected", ["subexpr", "eq", [["get", "order", ["loc", [null, [11, 82], [11, 87]]]], "reihe asc, band asc, brief_nummer asc"], [], ["loc", [null, [11, 77], [11, 129]]]]], ["inline", "t", ["seriesVolumeNumberAsc"], [], ["loc", [null, [11, 130], [11, 159]]]], ["attribute", "selected", ["subexpr", "eq", [["get", "order", ["loc", [null, [12, 85], [12, 90]]]], "reihe desc, band desc, brief_nummer desc"], [], ["loc", [null, [12, 80], [12, 135]]]]], ["inline", "t", ["seriesVolumeNumberDesc"], [], ["loc", [null, [12, 136], [12, 166]]]], ["attribute", "selected", ["subexpr", "eq", [["get", "order", ["loc", [null, [13, 68], [13, 73]]]], "datum_gregorianisch asc"], [], ["loc", [null, [13, 63], [13, 101]]]]], ["inline", "t", ["dateAsc"], [], ["loc", [null, [13, 102], [13, 117]]]], ["attribute", "selected", ["subexpr", "eq", [["get", "order", ["loc", [null, [14, 69], [14, 74]]]], "datum_gregorianisch desc"], [], ["loc", [null, [14, 64], [14, 103]]]]], ["inline", "t", ["dateDesc"], [], ["loc", [null, [14, 104], [14, 120]]]]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.6.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 16,
                  "column": 10
                },
                "end": {
                  "line": 18,
                  "column": 10
                }
              },
              "moduleName": "leibniz-frontend/templates/search.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "t", ["enterSearchTerm"], [], ["loc", [null, [17, 12], [17, 35]]]]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.6.2",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 8
              },
              "end": {
                "line": 19,
                "column": 8
              }
            },
            "moduleName": "leibniz-frontend/templates/search.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "query", ["loc", [null, [7, 16], [7, 21]]]]], [], 0, 1, ["loc", [null, [7, 10], [18, 17]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      return {
        meta: {
          "fragmentReason": {
            "name": "triple-curlies"
          },
          "revision": "Ember@2.6.2",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 23,
              "column": 0
            }
          },
          "moduleName": "leibniz-frontend/templates/search.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "header_main");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "header_title");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          dom.setAttribute(el3, "class", "header_info");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element9 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element9, 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(element9, [3]), 1, 1);
          return morphs;
        },
        statements: [["inline", "input", [], ["class", "search_input", "placeholder", ["subexpr", "t", ["searchNoun"], [], ["loc", [null, [4, 47], [4, 63]]]], "type", ["subexpr", "@mut", [["get", "text", ["loc", [null, [4, 69], [4, 73]]]]], [], []], "value", ["subexpr", "@mut", [["get", "query", ["loc", [null, [4, 80], [4, 85]]]]], [], []]], ["loc", [null, [4, 6], [4, 87]]]], ["block", "if", [["get", "rendered", ["loc", [null, [6, 14], [6, 22]]]]], [], 0, null, ["loc", [null, [6, 8], [19, 15]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.6.2",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 4
            },
            "end": {
              "line": 29,
              "column": 4
            }
          },
          "moduleName": "leibniz-frontend/templates/search.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["content", "content.error", ["loc", [null, [28, 6], [28, 23]]]]],
        locals: [],
        templates: []
      };
    }();
    var child2 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.6.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 34,
                  "column": 12
                },
                "end": {
                  "line": 37,
                  "column": 12
                }
              },
              "moduleName": "leibniz-frontend/templates/search.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "search_correspondents");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "search_id");
              var el2 = dom.createTextNode("(");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(", ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" N. ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(")");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [3]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              morphs[1] = dom.createMorphAt(element1, 1, 1);
              morphs[2] = dom.createMorphAt(element1, 3, 3);
              morphs[3] = dom.createMorphAt(element1, 5, 5);
              return morphs;
            },
            statements: [["content", "letter.korrespondenten_anzeige", ["loc", [null, [35, 50], [35, 84]]]], ["content", "letter.reihe", ["loc", [null, [36, 39], [36, 55]]]], ["content", "letter.band", ["loc", [null, [36, 57], [36, 72]]]], ["content", "letter.brief_nummer", ["loc", [null, [36, 86], [36, 109]]]]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.6.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 40,
                  "column": 14
                },
                "end": {
                  "line": 42,
                  "column": 14
                }
              },
              "moduleName": "leibniz-frontend/templates/search.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "search_location");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(",\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["content", "letter.ort_anzeige", ["loc", [null, [41, 46], [41, 68]]]]],
            locals: [],
            templates: []
          };
        }();
        var child2 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.6.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 44,
                  "column": 14
                },
                "end": {
                  "line": 48,
                  "column": 14
                }
              },
              "moduleName": "leibniz-frontend/templates/search.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("time");
              dom.setAttribute(el1, "class", "search_date");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element0, 'datetime');
              morphs[1] = dom.createMorphAt(element0, 1, 1);
              return morphs;
            },
            statements: [["attribute", "datetime", ["concat", [["get", "letter.datum_gregorianisch", ["loc", [null, [45, 54], [45, 80]]]]]]], ["content", "letter.datum_anzeige", ["loc", [null, [46, 18], [46, 42]]]]],
            locals: [],
            templates: []
          };
        }();
        var child3 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.6.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 52,
                  "column": 14
                },
                "end": {
                  "line": 54,
                  "column": 14
                }
              },
              "moduleName": "leibniz-frontend/templates/search.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createUnsafeMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["content", "letter.excerpt", ["loc", [null, [53, 16], [53, 36]]]]],
            locals: [],
            templates: []
          };
        }();
        var child4 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.6.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 54,
                  "column": 14
                },
                "end": {
                  "line": 56,
                  "column": 14
                }
              },
              "moduleName": "leibniz-frontend/templates/search.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "t", ["fulltextNotAvailable"], [], ["loc", [null, [55, 16], [55, 44]]]]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.6.2",
            "loc": {
              "source": null,
              "start": {
                "line": 32,
                "column": 8
              },
              "end": {
                "line": 59,
                "column": 8
              }
            },
            "moduleName": "leibniz-frontend/templates/search.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "search_info");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            dom.setAttribute(el2, "class", "search_excerpt");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var element3 = dom.childAt(element2, [3]);
            var morphs = new Array(5);
            morphs[0] = dom.createAttrMorph(element2, 'class');
            morphs[1] = dom.createMorphAt(element2, 1, 1);
            morphs[2] = dom.createMorphAt(element3, 1, 1);
            morphs[3] = dom.createMorphAt(element3, 3, 3);
            morphs[4] = dom.createMorphAt(dom.childAt(element2, [5]), 1, 1);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["search_result ", ["subexpr", "unless", [["get", "letter.sentByFocusPerson", ["loc", [null, [33, 44], [33, 68]]]], "-right"], [], ["loc", [null, [33, 35], [33, 79]]]]]]], ["block", "link-to", ["letter", ["get", "letter.id", ["loc", [null, [34, 32], [34, 41]]]]], ["class", "search_title"], 0, null, ["loc", [null, [34, 12], [37, 24]]]], ["block", "if", [["get", "letter.ort_anzeige", ["loc", [null, [40, 20], [40, 38]]]]], [], 1, null, ["loc", [null, [40, 14], [42, 21]]]], ["block", "if", [["get", "letter.datum_anzeige", ["loc", [null, [44, 20], [44, 40]]]]], [], 2, null, ["loc", [null, [44, 14], [48, 21]]]], ["block", "if", [["get", "letter.excerpt", ["loc", [null, [52, 20], [52, 34]]]]], [], 3, 4, ["loc", [null, [52, 14], [56, 21]]]]],
          locals: ["letter"],
          templates: [child0, child1, child2, child3, child4]
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.6.2",
          "loc": {
            "source": null,
            "start": {
              "line": 30,
              "column": 4
            },
            "end": {
              "line": 61,
              "column": 4
            }
          },
          "moduleName": "leibniz-frontend/templates/search.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("ul");
          dom.setAttribute(el1, "class", "search_results");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "each", [["get", "content.results", ["loc", [null, [32, 16], [32, 31]]]]], [], 0, null, ["loc", [null, [32, 8], [59, 17]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child3 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.6.2",
          "loc": {
            "source": null,
            "start": {
              "line": 61,
              "column": 4
            },
            "end": {
              "line": 65,
              "column": 4
            }
          },
          "moduleName": "leibniz-frontend/templates/search.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "content_loading");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "sr-only");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1, 1]), 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["loading"], [], ["loc", [null, [63, 30], [63, 47]]]]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.6.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 70,
            "column": 0
          }
        },
        "moduleName": "leibniz-frontend/templates/search.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("main");
        dom.setAttribute(el1, "class", "content");
        dom.setAttribute(el1, "id", "content");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "search");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element10 = dom.childAt(fragment, [2, 1]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(element10, 1, 1);
        morphs[2] = dom.createMorphAt(element10, 2, 2);
        morphs[3] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "header-container", [], [], 0, null, ["loc", [null, [1, 0], [23, 21]]]], ["block", "if", [["get", "content.error", ["loc", [null, [27, 10], [27, 23]]]]], [], 1, null, ["loc", [null, [27, 4], [29, 11]]]], ["block", "if", [["get", "rendered", ["loc", [null, [30, 10], [30, 18]]]]], [], 2, 3, ["loc", [null, [30, 4], [65, 11]]]], ["content", "outlet", ["loc", [null, [69, 0], [69, 10]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  }());
});