define('leibniz-frontend/components/drag-handle', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'drag-handle',
    mouseMove: function mouseMove(event) {
      var dragHandlerHeight = 98; // as set in CSS
      this.$().css('background-position', 'center ' + (event.offsetY - dragHandlerHeight / 2) + 'px');
    },
    mouseDown: function mouseDown() {
      var _this = this;

      var $this = this.$();
      var contentWidth = $('.content').outerWidth();
      $(window).on('mousemove', function (event) {
        var posX = event.pageX;
        if (posX > 99 && posX < contentWidth - 99) {
          var $leftLane = $this.prev('.lane');
          var $rightLane = $this.next('.lane');
          $leftLane.css('width', posX / contentWidth * 100 + '%');
          $rightLane.css('width', 100 - posX / contentWidth * 100 + '%');
          $this.css('left', '');
        }
      });
      $(window).off('mouseup').on('mouseup', function () {
        $(window).off('mousemove');
        _this.sendAction('afterResize');
        // TODO: Save new state
      });
    }
  });
});