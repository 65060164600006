define('leibniz-frontend/controllers/letter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['view'],
    laneTypes: ['manuscript', 'transcript', 'variants'],
    view: '{"lanes":[{"type":"transcript","width":50},{"type":"variants","width":50}]}'
  });
});