define('leibniz-frontend/components/letter-header', ['exports', 'leibniz-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    showCitation: false,
    showMetadata: false,
    tagName: '',
    texName: _environment.default.texName,
    actions: {
      changeLetter: function changeLetter(id) {
        this.sendAction('changeLetter', id);
      },
      toggleCitation: function toggleCitation() {
        Ember.$('.citation').slideToggle();
        this.set('showCitation', !this.get('showCitation'));
      },
      toggleMetadata: function toggleMetadata() {
        Ember.$('.metadata').slideToggle();
        this.set('showMetadata', !this.get('showMetadata'));
      }
    }
  });
});