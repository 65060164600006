define('leibniz-frontend/router', ['exports', 'leibniz-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType
  });

  Router.map(function () {
    this.route('404', { path: '*path' });
    this.route('start', { path: '/start' });
    this.route('letter', { path: '/letter/:letter_id' });
    this.route('search');
    this.route('savexml', { path: '/adw_goettingen_leibniz_briefportal.xml' });
  });

  exports.default = Router;
});