define("leibniz-frontend/templates/partials/letter-citation", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.6.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 26,
            "column": 0
          }
        },
        "moduleName": "leibniz-frontend/templates/partials/letter-citation.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("aside");
        dom.setAttribute(el1, "class", "citation");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h2");
        dom.setAttribute(el2, "class", "citation_heading");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" an\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(",\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(", ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(", in:");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    Gottfried Wilhelm Leibniz, Sämtliche Schriften und Briefe,\n    hrsg. v. d. Berlin-Brandenburgischen Akademie der Wissenschaften\n    u. d. Akademie der Wissenschaften zu Göttingen, Berlin:\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    Reihe ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(", Bd. ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(", N. ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3, "target", "_blank");
        var el4 = dom.createTextNode("PDF des Briefes in der Vollfassung");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [4]);
        var element2 = dom.childAt(element0, [6, 2]);
        var morphs = new Array(16);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(element1, 1, 1);
        morphs[2] = dom.createMorphAt(element1, 3, 3);
        morphs[3] = dom.createMorphAt(element1, 5, 5);
        morphs[4] = dom.createMorphAt(element1, 7, 7);
        morphs[5] = dom.createMorphAt(element1, 9, 9);
        morphs[6] = dom.createMorphAt(element1, 11, 11);
        morphs[7] = dom.createMorphAt(element1, 15, 15);
        morphs[8] = dom.createMorphAt(element1, 17, 17);
        morphs[9] = dom.createMorphAt(element1, 19, 19);
        morphs[10] = dom.createMorphAt(element1, 21, 21);
        morphs[11] = dom.createMorphAt(element1, 24, 24);
        morphs[12] = dom.createMorphAt(element1, 26, 26);
        morphs[13] = dom.createMorphAt(element1, 28, 28);
        morphs[14] = dom.createAttrMorph(element2, 'href');
        morphs[15] = dom.createMorphAt(element0, 8, 8);
        return morphs;
      },
      statements: [["inline", "t", ["citation"], [], ["loc", [null, [2, 31], [2, 47]]]], ["content", "content.absender_vorname", ["loc", [null, [7, 4], [7, 32]]]], ["content", "content.absender_nachname", ["loc", [null, [7, 33], [7, 62]]]], ["content", "content.empfaenger_vorname", ["loc", [null, [8, 4], [8, 34]]]], ["content", "content.empfaenger_nachname", ["loc", [null, [8, 35], [8, 66]]]], ["content", "content.ort", ["loc", [null, [9, 4], [9, 19]]]], ["content", "content.datum_anzeige", ["loc", [null, [9, 21], [9, 46]]]], ["inline", "if", [["subexpr", "eq", [["get", "content.band", ["loc", [null, [13, 13], [13, 25]]]], "5"], [], ["loc", [null, [13, 9], [13, 30]]]], "Akademie Verlag 2003,"], [], ["loc", [null, [13, 4], [13, 56]]]], ["inline", "if", [["subexpr", "eq", [["get", "content.band", ["loc", [null, [14, 13], [14, 25]]]], "6"], [], ["loc", [null, [14, 9], [14, 30]]]], "Akademie Verlag 2004,"], [], ["loc", [null, [14, 4], [14, 56]]]], ["inline", "if", [["subexpr", "eq", [["get", "content.band", ["loc", [null, [15, 13], [15, 25]]]], "7"], [], ["loc", [null, [15, 9], [15, 30]]]], "Akademie Verlag 2011,"], [], ["loc", [null, [15, 4], [15, 56]]]], ["inline", "if", [["subexpr", "eq", [["get", "content.band", ["loc", [null, [16, 13], [16, 25]]]], "8"], [], ["loc", [null, [16, 9], [16, 30]]]], "De Gruyter Verlag 2015,"], [], ["loc", [null, [16, 4], [16, 58]]]], ["content", "content.reihe", ["loc", [null, [17, 10], [17, 27]]]], ["content", "content.band", ["loc", [null, [17, 33], [17, 49]]]], ["content", "content.brief_nummer", ["loc", [null, [17, 54], [17, 78]]]], ["attribute", "href", ["concat", [["get", "content.brief_url", ["loc", [null, [21, 15], [21, 32]]]]]]], ["content", "yield", ["loc", [null, [24, 2], [24, 11]]]]],
      locals: [],
      templates: []
    };
  }());
});