define('leibniz-frontend/routes/application', ['exports', 'leibniz-frontend/mixins/solr'], function (exports, _solr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_solr.default, {
    intl: Ember.inject.service(),
    model: function model(params) {
      return this.loadAllLettersInVolume();
    },
    beforeModel: function beforeModel() {
      // define the app's runtime locale
      // For example, here you would maybe do an API lookup to resolver
      // which locale the user should be targeted and perhaps lazily
      // load translations using XHR and calling intl's `addTranslation`/`addTranslations`
      // method with the results of the XHR request
      this.get('intl').setLocale('de-de');
    },
    loadAllLettersInVolume: function loadAllLettersInVolume() {
      return this.query('type:brief', { fl: 'id, band, reihe, brief_nummer, korrespondenz', sort: 'reihe asc, band asc, brief_nummer asc' }).then(function (json) {
        if (json.response.docs.length > 0) {
          return json.response.docs;
        }
      });
    },
    renderTemplate: function renderTemplate() {
      this.render();
      this.render('nav', {
        into: 'application',
        outlet: 'nav'
      });
    },
    afterModel: function afterModel() {}
  });
});