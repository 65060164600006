define('leibniz-frontend/helpers/incr', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var incr = function incr(params) {
    return params[0] + 1;
  };

  exports.default = Ember.Helper.helper(incr);
});