define("leibniz-frontend/templates/application", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.6.2",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 12
              },
              "end": {
                "line": 13,
                "column": 12
              }
            },
            "moduleName": "leibniz-frontend/templates/application.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("option");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element2, 'value');
            morphs[1] = dom.createAttrMorph(element2, 'selected');
            morphs[2] = dom.createMorphAt(element2, 1, 1);
            return morphs;
          },
          statements: [["attribute", "value", ["get", "correspondence.id", ["loc", [null, [10, 30], [10, 47]]]]], ["attribute", "selected", ["subexpr", "eq", [["get", "current-letter.korrespondenz", ["loc", [null, [10, 64], [10, 92]]]], ["get", "correspondence.korrespondenz", ["loc", [null, [10, 93], [10, 121]]]]], [], ["loc", [null, [10, 59], [10, 123]]]]], ["content", "correspondence.korrespondenz", ["loc", [null, [11, 16], [11, 48]]]]],
          locals: ["correspondence"],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.6.2",
            "loc": {
              "source": null,
              "start": {
                "line": 15,
                "column": 10
              },
              "end": {
                "line": 17,
                "column": 10
              }
            },
            "moduleName": "leibniz-frontend/templates/application.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createUnsafeMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", ["correspondents"], [], ["loc", [null, [16, 12], [16, 36]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.6.2",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 6
            },
            "end": {
              "line": 19,
              "column": 6
            }
          },
          "moduleName": "leibniz-frontend/templates/application.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "top-bar_correspondence");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(":\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("select");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var element4 = dom.childAt(element3, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createUnsafeMorphAt(element3, 1, 1);
          morphs[1] = dom.createAttrMorph(element4, 'onChange');
          morphs[2] = dom.createMorphAt(element4, 1, 1);
          morphs[3] = dom.createMorphAt(element3, 5, 5);
          return morphs;
        },
        statements: [["inline", "t", ["correspondence"], [], ["loc", [null, [7, 8], [7, 32]]]], ["attribute", "onChange", ["subexpr", "action", ["changeTo"], ["value", "target.value"], ["loc", [null, [8, 27], [8, 69]]]]], ["block", "each", [["get", "correspondences", ["loc", [null, [9, 20], [9, 35]]]]], [], 0, null, ["loc", [null, [9, 12], [13, 21]]]], ["block", "pop-up", [], [], 1, null, ["loc", [null, [15, 10], [17, 21]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.6.2",
          "loc": {
            "source": null,
            "start": {
              "line": 21,
              "column": 6
            },
            "end": {
              "line": 26,
              "column": 6
            }
          },
          "moduleName": "leibniz-frontend/templates/application.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("form");
          dom.setAttribute(el1, "class", "top-bar_search");
          dom.setAttribute(el1, "method", "get");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "type", "submit");
          dom.setAttribute(el2, "class", "top-bar_search-submit");
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "sr-only");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element0, 1, 1);
          morphs[1] = dom.createElementMorph(element1);
          morphs[2] = dom.createMorphAt(dom.childAt(element1, [0]), 0, 0);
          return morphs;
        },
        statements: [["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "query", ["loc", [null, [23, 24], [23, 29]]]]], [], []], "type", ["subexpr", "@mut", [["get", "text", ["loc", [null, [23, 35], [23, 39]]]]], [], []], "class", "top-bar_search-input", "placeholder", ["subexpr", "t", ["searchNoun"], [], ["loc", [null, [23, 81], [23, 97]]]]], ["loc", [null, [23, 10], [23, 99]]]], ["element", "action", ["search"], [], ["loc", [null, [24, 62], [24, 81]]]], ["inline", "t", ["search"], [], ["loc", [null, [24, 103], [24, 117]]]]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.6.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 41,
            "column": 0
          }
        },
        "moduleName": "leibniz-frontend/templates/application.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "top-bar");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3, "class", "top-bar_title");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "top-bar_right");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4, "class", "top-bar_search");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4, "class", "top-bar_toggle-nav");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("button");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "sr-only");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [0]);
        var element6 = dom.childAt(element5, [1]);
        var element7 = dom.childAt(element6, [1]);
        var element8 = dom.childAt(element6, [3]);
        var element9 = dom.childAt(element8, [5]);
        var element10 = dom.childAt(fragment, [4]);
        var morphs = new Array(12);
        morphs[0] = dom.createAttrMorph(element5, 'class');
        morphs[1] = dom.createAttrMorph(element7, 'href');
        morphs[2] = dom.createMorphAt(element7, 0, 0);
        morphs[3] = dom.createMorphAt(element8, 1, 1);
        morphs[4] = dom.createMorphAt(dom.childAt(element8, [3]), 1, 1);
        morphs[5] = dom.createElementMorph(element9);
        morphs[6] = dom.createMorphAt(element9, 0, 0);
        morphs[7] = dom.createMorphAt(element5, 3, 3);
        morphs[8] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[9] = dom.createAttrMorph(element10, 'class');
        morphs[10] = dom.createElementMorph(element10);
        morphs[11] = dom.createMorphAt(dom.childAt(element10, [1]), 0, 0);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["main ", ["subexpr", "if", [["get", "showNav", ["loc", [null, [1, 22], [1, 29]]]], "-show-nav"], [], ["loc", [null, [1, 17], [1, 43]]]]]]], ["attribute", "href", ["concat", [["get", "baseURL", ["loc", [null, [3, 15], [3, 22]]]]]]], ["inline", "t", ["leibnizLetters"], [], ["loc", [null, [3, 48], [3, 70]]]], ["block", "if", [["get", "correspondences", ["loc", [null, [5, 12], [5, 27]]]]], [], 0, null, ["loc", [null, [5, 6], [19, 13]]]], ["block", "if", [["get", "showSearch", ["loc", [null, [21, 12], [21, 22]]]]], [], 1, null, ["loc", [null, [21, 6], [26, 13]]]], ["element", "action", ["toggleNav"], [], ["loc", [null, [28, 41], [28, 63]]]], ["inline", "t", ["menu"], [], ["loc", [null, [28, 64], [28, 76]]]], ["content", "outlet", ["loc", [null, [32, 2], [32, 12]]]], ["inline", "outlet", ["nav"], [], ["loc", [null, [36, 0], [36, 16]]]], ["attribute", "class", ["concat", ["main_scroll-top ", ["subexpr", "if", [["get", "showScrolltop", ["loc", [null, [38, 36], [38, 49]]]], "-visible"], [], ["loc", [null, [38, 31], [38, 62]]]]]]], ["element", "action", ["scrollToTop"], [], ["loc", [null, [38, 64], [38, 88]]]], ["inline", "t", ["scrollToTop"], [], ["loc", [null, [39, 24], [39, 43]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});