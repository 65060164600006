define('leibniz-frontend/mixins/lane', ['exports', 'leibniz-frontend/mixins/scrolling'], function (exports, _scrolling) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_scrolling.default, {
    classNames: 'lane',
    classNameBindings: ['laneType'],
    tagName: 'section',
    laneType: Ember.computed(function () {
      var lane = this.get('lane');
      return lane ? this.get('lane').type : '';
    }),
    actions: {
      toggleLaneTypeDropdown: function toggleLaneTypeDropdown() {
        this.$().find('.lane-type_dropdown').toggleClass('-open');
      },
      changeLaneType: function changeLaneType() {
        // TODO
      },
      positionVariants: function positionVariants() {
        this.sendAction('positionVariants');
      }
    },
    didInsertElement: function didInsertElement() {
      var $this = this.$();
      var lane = this.get('lane');
      if (typeof lane !== 'undefined' && lane.hasOwnProperty('width')) {
        $this.css({ width: lane.width + '%' });
      }
    },
    scrolled: function scrolled(scrollPos) {
      var $laneType = this.$('.lane-type');
      if ($laneType.length === 0) {
        return;
      }
      var headerHeight = this.$().offset().top + $laneType.outerHeight();
      var scrolledBelowLaneHeader = scrollPos > headerHeight;
      this.$('.lane_actions').toggleClass('-fixed', scrolledBelowLaneHeader).css({
        top: scrollPos - headerHeight
      });
    }
  });
});